import { createAction } from "redux-actions";
import * as constant from "../../utils/constant";

export const getUserRequest = createAction(constant.GET_USER_REQUEST);
export const getUserSuccess = createAction(constant.GET_USER_SUCCESS);
export const getUserError = createAction(constant.GET_USER_ERROR);

export const getProductRequest = createAction(constant.GET_PRODUCT_REQUEST);
export const getProductSuccess = createAction(constant.GET_PRODUCT_SUCCESS);
export const getProductError = createAction(constant.GET_PRODUCT_ERROR);

export const getVendorProfileRequest = createAction(
  constant.GET_VENDOR_PROFILE_REQUEST
);
export const getVendorProfileSuccess = createAction(
  constant.GET_VENDOR_PROFILE_SUCCESS
);
export const getVendorProfileError = createAction(
  constant.GET_VENDOR_PROFILE_ERROR
);

export const getCancelOrdersRequest = createAction(
  constant.GET_CANCEL_ORDERS_REQUEST
);
export const getCancelOrdersSuccess = createAction(
  constant.GET_CANCEL_ORDERS_SUCCESS
);
export const getCancelOrdersError = createAction(
  constant.GET_CANCEL_ORDERS_ERROR
);

export const getCategoriesRequest = createAction(
  constant.GET_CATEGORIES_REQUEST
);
export const getCategoriesSuccess = createAction(
  constant.GET_CATEGORIES_SUCCESS
);
export const getCategoriesError = createAction(constant.GET_CATEGORIES_ERROR);

export const getReviewRequest = createAction(constant.GET_REVIEW_REQUEST);
export const getReviewSuccess = createAction(constant.GET_REVIEW_SUCCESS);
export const getReviewError = createAction(constant.GET_REVIEW_ERROR);

export const getReviewDetailRequest = createAction(
  constant.GET_REVIEW_DETAIL_REQUEST
);
export const getReviewDetailSuccess = createAction(
  constant.GET_REVIEW_DETAIL_SUCCESS
);
export const getReviewDetailError = createAction(
  constant.GET_REVIEW_DETAIL_ERROR
);

export const setUserDetailRequest = createAction(
  constant.SET_USER_DETAIL_REQUEST
);
export const setUserDetailSuccess = createAction(
  constant.SET_USER_DETAIL_SUCCESS
);
export const setUserDetailError = createAction(constant.SET_USER_DETAIL_ERROR);

export const getOrderRequest = createAction(constant.GET_ORDER_REQUEST);
export const getOrderSuccess = createAction(constant.GET_ORDER_SUCCESS);
export const getOrderError = createAction(constant.GET_ORDER_ERROR);

export const getShippedOrdersRequest = createAction(
  constant.GET_SHIPPED_ORDERS_REQUEST
);
export const getShippedOrdersSuccess = createAction(
  constant.GET_SHIPPED_ORDERS_SUCCESS
);
export const getShippedOrdersError = createAction(
  constant.GET_SHIPPED_ORDERS_ERROR
);

export const getDeclinedOrdersRequest = createAction(
  constant.GET_DECLINED_ORDERS_REQUEST
);
export const getDeclinedOrdersSuccess = createAction(
  constant.GET_DECLINED_ORDERS_SUCCESS
);
export const getDeclinedOrdersError = createAction(
  constant.GET_DECLINED_ORDERS_ERROR
);

export const getExportOrderRequest = createAction(
  constant.GET_EXPORT_ORDER_REQUEST
);
export const getExportOrderSuccess = createAction(
  constant.GET_EXPORT_ORDER_SUCCESS
);
export const getExportOrderError = createAction(
  constant.GET_EXPORT_ORDER_ERROR
);

export const getOrderDetailRequest = createAction(
  constant.GET_ORDER_DETAIL_REQUEST
);
export const getOrderDetailSuccess = createAction(
  constant.GET_ORDER_DETAIL_SUCCESS
);
export const getOrderDetailError = createAction(
  constant.GET_ORDER_DETAIL_ERROR
);

export const getProductDetailRequest = createAction(
  constant.GET_PRODUCT_DETAIL_REQUEST
);
export const getProductDetailSuccess = createAction(
  constant.GET_PRODUCT_DETAIL_SUCCESS
);
export const getProductDetailError = createAction(
  constant.GET_PRODUCT_DETAIL_ERROR
);

export const getVendorRequest = createAction(constant.GET_VENDOR_REQUEST);
export const getVendorSuccess = createAction(constant.GET_VENDOR_SUCCESS);
export const getVendorError = createAction(constant.GET_VENDOR_ERROR);

export const getCartProductRequest = createAction(
  constant.GET_CART_PRODUCT_REQUEST
);
export const getCartProductSuccess = createAction(
  constant.GET_CART_PRODUCT_SUCCESS
);
export const getCartProductError = createAction(
  constant.GET_CART_PRODUCT_ERROR
);

export const getPaidOrdersRequest = createAction(
  constant.GET_PAID_ORDERS_REQUEST
);
export const getPaidOrdersSuccess = createAction(
  constant.GET_PAID_ORDERS_SUCCESS
);
export const getPaidOrdersError = createAction(constant.GET_PAID_ORDERS_ERROR);

export const setVendorDetailRequest = createAction(
  constant.SET_VENDOR_DETAIL_REQUEST
);
export const setVendorDetailSuccess = createAction(
  constant.SET_VENDOR_DETAIL_SUCCESS
);
export const setVendorDetailError = createAction(
  constant.SET_VENDOR_DETAIL_ERROR
);

export const getProductVendorRequest = createAction(
  constant.GET_PRODUCT_VENDOR_REQUEST
);
export const getProductVendorSuccess = createAction(
  constant.GET_PRODUCT_VENDOR_SUCCESS
);
export const getProductVendorError = createAction(
  constant.GET_PRODUCT_VENDOR_ERROR
);

export const getDashboardAdminRequest = createAction(
  constant.GET_DASHBOARD_ADMIN_REQUEST
);
export const getDashboardAdminSuccess = createAction(
  constant.GET_DASHBOARD_ADMIN_SUCCESS
);
export const getDashboardAdminError = createAction(
  constant.GET_DASHBOARD_ADMIN_ERROR
);

export const getDashboardVendorRequest = createAction(
  constant.GET_DASHBOARD_VENDOR_REQUEST
);
export const getDashboardVendorSuccess = createAction(
  constant.GET_DASHBOARD_VENDOR_SUCCESS
);
export const getDashboardVendorError = createAction(
  constant.GET_DASHBOARD_VENDOR_ERROR
);

export const getTransactionPaymentFailedRequest = createAction(
  constant.GET_TRANSACTION_PAYMENT_FAILED_REQUEST
);
export const getTransactionPaymentFailedSuccess = createAction(
  constant.GET_TRANSACTION_PAYMENT_FAILED_SUCCESS
);
export const getTransactionPaymentFailedError = createAction(
  constant.GET_TRANSACTION_PAYMENT_FAILED_ERROR
);

export const getTransactionPaymentCancelRequest = createAction(
  constant.GET_TRANSACTION_PAYMENT_CANCEL_REQUEST
);
export const getTransactionPaymentCancelSuccess = createAction(
  constant.GET_TRANSACTION_PAYMENT_CANCEL_SUCCESS
);
export const getTransactionPaymentCancelError = createAction(
  constant.GET_TRANSACTION_PAYMENT_CANCEL_ERROR
);