import * as constant from '../../utils/constant';

const initialState = {
  fetching: false,
  orderGetAllData: {},
  error: {},
};

export const GetAllOrder = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_ORDER_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_ORDER_SUCCESS:
      return {
        ...state,
        orderGetAllData: action.payload,
        fetching: false,
      };
    case constant.GET_ORDER_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
