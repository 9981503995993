import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const UpdateReviewAction = async (id, rating, comment) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post(
      "review/update",
      { id, comment, rating },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      return res.data;
    }
  } catch (err) {
    return null;
  }
};
