import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./vendorStyle";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { VendorDetailsAction } from "../../redux/action/VendorDetailsAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "../../components/common_Component/CommonLoader";

export default function VendorDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const vendorDetails = useSelector(
    (state) => state.setVendorDetail.setVendorData
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleDetails = async () => {
    dispatch(VendorDetailsAction(location.state._id));
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    handleDetails();
  }, []);

  const hanldeGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ArrowBackIcon style={{ margin: "2px" }} onClick={hanldeGoBack} />
            <ComponentTitle pageTitle="Vendor Details" />
          </div>
          <Paper className={classes.paper}>
            <Grid item xs={12} md={4} display={"flex"}>
              <Typography variant="h6" marginRight={"5px"}>
                {vendorDetails.first_name}
              </Typography>
            </Grid>
            <Divider style={{ margin: "16px 0" }} />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <EmailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Email" secondary={vendorDetails.email} />
              </ListItem>
              <Typography variant="p"  style={{ margin: "16px" }}>
              Total Charge In %: {vendorDetails.commission}%
              </Typography>
            </List>
          </Paper>
        </Container>
      )}
    </>
  );
}
