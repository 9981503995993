import { toast } from "react-toastify";
import { apiInstance } from "../../httpClient";
import { setAdminSessionToken } from "../../utils/Storage";

export const LoginAdmin = async (data, setLoading) => {
  setLoading(true);
  try {
    const res = await apiInstance.post("auth/login", data);

    setLoading(false);
    if (res.status === 200) {
      if (res.data.isSuccess) {
        setAdminSessionToken(res.data.data.token);
      }
      return res.data;
    }
    return null;
  } catch (err) {
    toast.error(err?.message);
    setLoading(false);
    return err.response;
  }
};
