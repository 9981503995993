import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { GetAllPaidOrders } from "../../redux/action/GetAllPaidOrders";
import moment from "moment";
import momenttz from 'moment-timezone';
import { CircularProgress } from "../../components/common_Component/CommonLoader";

const PaidOrder = ({ search, page, onPageChange, vendor }) => {
  const dispatch = useDispatch();

  const getPaidOrders = useSelector(
    (state) => state?.GetPaidOrders?.getAllPaidOrders
  );

  const fetching = useSelector((state) => state?.GetPaidOrders?.fetching);

  const handleGetAllPaidOrders = (pageNumber, searchTerm) => {
    dispatch(GetAllPaidOrders(pageNumber + 1, searchTerm));
  };

  useEffect(() => {
    if (vendor === "All Vendor") {
      handleGetAllPaidOrders(page, search);
    } else {
      handleGetAllPaidOrders(page, search || vendor);
    }
  }, [page, search || vendor]);

  const columns = [
    { id: "id", label: "Id", minWidth: 30 },
    { id: "userName", label: "User Name", minWidth: 80 },
    { id: "vendorName", label: "Vendor Name", minWidth: 100 },
    {
      id: "productCount",
      label: "No. Of Products",
      minWidth: 100,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 100,
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 50,
    },
  ];

  const formatDateInCentralTime = (date) => {
    // const centralTimeOffset = -5 * 60; // Central Standard Time (CST) is UTC-5
    // const centralDate = moment(date).utcOffset(centralTimeOffset);
    const timezone = momenttz.tz.guess(); // This guesses the user's local timezone
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#a98f57 ",
                    color: "#fff",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {getPaidOrders?.result?.length ? (
                  getPaidOrders.result.map((e, index) => (
                    <TableRow hover tabIndex={-1} key={e._id}>
                      <TableCell>{e?.orderNumber}</TableCell>
                      <TableCell>
                        {(e.userDetails?.first_name ?? "") +
                          " " +
                          (e.userDetails?.last_name ?? "")}
                      </TableCell>
                      <TableCell>
                      <p className="text-overflow">{e.vendorNames}</p>
                      </TableCell>
                      <TableCell align="center">
                        {e.accounting?.cartAccountingList?.length}
                      </TableCell>
                      <TableCell>
                        {formatDateInCentralTime(e?.createdAt)}
                      </TableCell>
                      <TableCell>
                        ${Number(e.accounting?.finalTotal).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No Transaction Available.
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={getPaidOrders?.meta?.totalCount || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          !count ? "0-0 of 0" : `${from}-${to} of ${count}`
        }
      />
    </>
  );
};

export default PaidOrder;
