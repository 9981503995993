import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ChangePasswordAction } from "../../redux/action/ChangePasswordAction";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { removeAdminSession } from "../../utils/Storage";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "../../components/common_Component/CommonLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  p: 4,
};

const ChangePasswordButton = styled(Button)({
  background: "#a98f57 ",
  color: "#f1f3fa",
  width: "100%",
  height: "50px",
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const vendorId = localStorage.getItem("vendorId");

  const handleClick = async () => {
    try {
      if (!oldPassword && !newPassword && !confirmPassword) {
        setOldPasswordError(
          oldPasswordError ? "" : "* Old Password is required"
        );
        setNewPasswordError(
          newPasswordError ? "" : "* New Password is required"
        );
        setConfirmPasswordError(
          confirmPasswordError ? "" : "* Confirm Password is required"
        );
        return;
      }

      if (!oldPassword.length) {
        setOldPasswordError(
          oldPasswordError ? "" : "* Old Password is required"
        );
        return;
      }

      if (!newPassword.length) {
        setNewPasswordError(
          newPasswordError ? "" : "* New Password is required"
        );
        return;
      }

      if (!confirmPassword.length) {
        setConfirmPasswordError(
          confirmPasswordError ? "" : "* Confirm Password is required"
        );
        return;
      }

      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      const changePassword = await ChangePasswordAction(
        vendorId,
        data,
        setLoading
      );
      if (changePassword.isSuccess) {
        setLoading(false);
        toast.success(changePassword?.displayMsg);
        removeAdminSession();
        navigate("/login");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error during login", error?.message);
      toast.error(error?.message);
    }
  };

  const PasswordCkeck = (e, setPasswordError) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    const isPasswordValid = passwordRegex.test(e.target.value);
    const isNotEmpty = e.target.value.length > 0;
    const isLengthValid = e.target.value.length >= 8; // Minimum length requirement
    setPasswordError(
      !isPasswordValid
        ? "Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long."
        : !isLengthValid && isNotEmpty
        ? "Password must be at least 8 characters long."
        : !isNotEmpty
        ? "Password is required."
        : ""
    );
  };
  return (
    <>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography variant="h5">Change Password</Typography>
        </Stack>
        <FormControl variant="outlined" className="margin-top-bottom-5">
          <TextField
            name="oldPassword"
            label="Old Password"
            value={oldPassword}
            type={showOldPassword ? "text" : "password"}
            onChange={(e) => {
              setOldPassword(e.target.value);
              PasswordCkeck(e, setOldPasswordError);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowOldPassword((show) => !show);
                    }}
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="invalid-msg mt-2">{oldPasswordError}</div>
        </FormControl>
        <FormControl variant="outlined" className="margin-top-bottom-5">
          <TextField
            name="newPassword"
            label="New Password"
            value={newPassword}
            type={showNewPassword ? "text" : "password"}
            onChange={(e) => {
              setNewPassword(e.target.value);
              PasswordCkeck(e, setNewPasswordError);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowNewPassword((show) => !show);
                    }}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="invalid-msg mt-2">{newPasswordError}</div>
        </FormControl>
        <FormControl variant="outlined" className="margin-top-bottom-5">
          <TextField
            name="confirmPassword"
            label="Confirm Password"
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              PasswordCkeck(e, setConfirmPasswordError);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowConfirmPassword((show) => !show);
                    }}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="invalid-msg mt-2">{confirmPasswordError}</div>
        </FormControl>
        <Stack
          justifyContent="center"
          alignItems="center"
          display="flex"
          className="margin-top-bottom-5 mb-0"
        >
          <ChangePasswordButton variant="text" onClick={handleClick}>
            {loading ? (
              <RotatingLines />
            ) : (
              "Change Password"
            )}
          </ChangePasswordButton>
        </Stack>
      </Box>
    </>
  );
};

export default ChangePassword;
