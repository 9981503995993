import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
  Stack,
  IconButton,
  Button,
  Modal,
  InputAdornment,
  Alert,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { UpdateVendorAction } from "../../redux/action/UpdateVendorAction";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AddVendor } from "../../redux/action/AddVendorAction";
import { EMAIL_REGEX } from "../../utils/constant";
import Snackbar from "@mui/material/Snackbar";
import { useStyles } from "./commonCss";
import { default_image } from "../../assets/image";
import InputMask from "react-input-mask";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};

export default function VendorModal(props) {
  const imageURL = process.env.REACT_APP_IMAGE_URL;

  const [emailError, setEmailError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [taxIdError, setTaxIdError] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [display, setDisplay] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const vendorDetails = useSelector(
    (state) => state.setVendorDetail.setVendorData
  );
  const fetching = useSelector((state) => state.setVendorDetail.fetching);

  const [vendor, setVendor] = useState({
    id: vendorDetails?._id || "",
    firstName: vendorDetails?.first_name || "",
    email: vendorDetails?.email || "",
    website: vendorDetails?.website || "",
    status: vendorDetails?.status || "A",
    password: vendorDetails?.password || "",
    phoneNumber: vendorDetails?.phoneNumber || "",
    address: vendorDetails?.address || "",
    taxId: vendorDetails?.taxId || "",
    image: vendorDetails?.image || "",
    display: vendorDetails?.display,
  });

  useEffect(() => {
    if ((props.isVendor && vendorDetails) || (props.isOpen && props.data)) {
      setIsModalOpen(true);
      setVendor({
        id: vendorDetails?._id || "",
        firstName: vendorDetails?.first_name || "",
        email: vendorDetails?.email || "",
        website: vendorDetails?.website || "",
        status: vendorDetails?.status || "A",
        password: "",
        phoneNumber: vendorDetails?.phone_number || "",
        address: vendorDetails?.address || "",
        taxId: vendorDetails?.taxId || "",
        image: vendorDetails?.image || "",
        display: vendorDetails?.display,
      });
      setDisplay(vendorDetails?.display);
      setLogoImage(imageURL + vendorDetails?.image);
    } else {
      setIsModalOpen(false);
      setVendor({
        id: "",
        firstName: "",
        email: "",
        website: "",
        password: "",
        phoneNumber: "",
        address: "",
        taxId: "",
        image: "",
        display: false,
      });
      setLogoImage("");
      setDisplay(false);
    }
  }, [props.isVendor, vendorDetails, props.isOpen, props.data]);

  const handelSubmit = async () => {
    let valid = true;

    if (!vendor.firstName) {
      setFirstNameError("* Company Name is required");
      valid = false;
    }
    if (!vendor.email) {
      setEmailError("* Email is required");
      valid = false;
    } else if (!EMAIL_REGEX.test(vendor.email)) {
      setEmailError("* Invalid Email");
      valid = false;
    }
    if (!vendor.website) {
      setWebsiteError("* Website is required");
      valid = false;
    }
    if (!props.isVendor) {
      if (!vendor.password) {
        setPasswordError("* Password is required");
        valid = false;
      }
    }
    if (!vendor.phoneNumber) {
      setPhoneNumberError("* Phone Number is required");
      return;
    }

    const digitCount = vendor.phoneNumber.replace(/\D/g, "").length;
    if (digitCount !== 10) {
      setPhoneNumberError("* Phone Number must contain exactly 10 digits");
      return;
    }
    if (!vendor.address) {
      setAddressError("* Address is required");
      valid = false;
    }
    if (!logoImage) {
      setLogoError(true);
      valid = false;
    }

    if (!valid) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("first_name", vendor.firstName);
      if (vendor.password) {
        formData.append("password", vendor.password);
      }
      formData.append("email", vendor.email);
      formData.append("website", vendor.website);
      formData.append("status", vendor.status ?? "A");
      formData.append("taxId", vendor.taxId || "");
      formData.append("image", vendor.image);
      formData.append("phone_number", vendor.phoneNumber);
      formData.append("address", vendor.address);
      formData.append("display", display);

      if (props.isVendor) {
        formData.append("id", vendor.id);
        const response = await UpdateVendorAction(formData);
        if (response.isSuccess) {
          setSuccessOpen(true);
          props.handleClose();
          props.handleVendorGetAll("", 0);
        } else {
          setErrorOpen(true);
          setLoading(false);
          setErrorMsg(response.data.message);
        }
      } else {
        const res = await AddVendor(formData, setLoading);
        if (res.status === 200 || res.status === 201) {
          setSuccessOpen(true);
          props.handleVendorGetAll("", 0);
          props.handleClose();
          setLoading(false);
        } else {
          setErrorOpen(true);
          setErrorMsg(res?.data?.message || res?.data?.displayMsg);
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFormData = useCallback(
    (e) => {
      const { name, value } = e.target;
      setVendor({ ...vendor, [name]: value });

      if (name === "firstName") setFirstNameError("");
      if (name === "email") setEmailError("");
      if (name === "website") setWebsiteError("");
      if (name === "password") setPasswordError("");
      if (name === "phoneNumber") setPhoneNumberError("");
      if (name === "address") setAddressError("");
    },
    [vendor]
  );

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handelLogoImage = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setLogoImage(imageUrl);
      setVendor((prevState) => ({
        ...prevState,
        image: file,
      }));
      setLogoError(false);
    }
  };

  const classes = useStyles();
  return (
    <>
      <div>
        <Snackbar
          open={isSuccessOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" onClose={handleSuccessClose}>
            Vendor updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" onClose={handleErrorClose}>
            {errorMsg}
          </Alert>
        </Snackbar>
      </div>
      <Box>
        <Modal open={props.isOpen} onClose={props.handleClose}>
          <>
            <Box sx={style} className="product-scroll">
              {fetching ? (
                <div className={classes.center}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      marginBottom: "15px",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h5">
                        {props.isVendor ? "Edit Vendor" : "Create Vendor"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Typography variant="h6" color="#6c757d" marginBottom={0.5}>
                    Logo
                  </Typography>
                  <div className="upload-logo">
                    <img
                      src={logoImage ? logoImage : default_image}
                      className="logo-img"
                      crossorigin="anonymous"
                      alt="logo"
                    />
                    <input
                      type="file"
                      name="file"
                      accept=".png, .jpg, .jpeg"
                      className="upload_input"
                      onChange={handelLogoImage}
                    />
                  </div>
                  {logoError && (
                    <p className="invalid-msg mt-2">* Please Add Logo</p>
                  )}
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginTop: "0",
                    }}
                  >
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <TextField
                          fullWidth
                          name="firstName"
                          id="firstName"
                          label={
                            <span>
                              Company Name <span className={classes.red}>*</span>
                            </span>
                          }
                          variant="outlined"
                          onChange={handleFormData}
                          value={vendor.firstName}
                        />
                        <div className="invalid-msg mt-2">{firstNameError}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <InputMask
                          mask="(999) 999-9999"
                          value={vendor.phoneNumber}
                          onChange={handleFormData}
                        >
                          {(inputProps) => (
                            <TextField
                              {...inputProps}
                              fullWidth
                              name="phoneNumber"
                              id="phoneNumber"
                              variant="outlined"
                              placeholder="Phone Number"
                              className={classes.phoneNumberInput}
                              label={
                                <span>
                                  Phone Number{" "}
                                  <span className={classes.red}>*</span>
                                </span>
                              }
                            />
                          )}
                        </InputMask>
                        <div className="invalid-msg mt-2">
                          {phoneNumberError}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <TextField
                          fullWidth
                          name="email"
                          id="email"
                          disabled={props.isVendor}
                          label={
                            <span>
                              Email <span className={classes.red}>*</span>
                            </span>
                          }
                          variant="outlined"
                          onChange={handleFormData}
                          value={vendor.email}
                        />
                        <div className="invalid-msg mt-2">{emailError}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <TextField
                          fullWidth
                          name="website"
                          id="website"
                          label={
                            <span>
                              Website <span className={classes.red}>*</span>
                            </span>
                          }
                          variant="outlined"
                          onChange={handleFormData}
                          value={vendor.website}
                        />
                        <div className="invalid-msg mt-2">{websiteError}</div>
                      </Box>
                    </Grid>
                    {!props.isVendor && (
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            maxWidth: "380px",
                            paddingTop: 0,
                          }}
                        >
                          <TextField
                            fullWidth
                            name="password"
                            id="password"
                            label={
                              <span>
                                Password <span className={classes.red}>*</span>
                              </span>
                            }
                            variant="outlined"
                            onChange={handleFormData}
                            value={vendor.password}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <div className="invalid-msg mt-2">
                            {passwordError}
                          </div>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <TextField
                          fullWidth
                          name="address"
                          id="address"
                          label={
                            <span>
                              Address <span className={classes.red}>*</span>
                            </span>
                          }
                          variant="outlined"
                          inputMode="numeric"
                          onChange={handleFormData}
                          value={vendor.address}
                        />
                        <div className="invalid-msg mt-2">{addressError}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          maxWidth: "380px",
                          paddingTop: 0,
                        }}
                      >
                        <TextField
                          fullWidth
                          name="taxId"
                          id="taxId"
                          label={<span>Tax ID</span>}
                          variant="outlined"
                          inputMode="numeric"
                          onChange={handleFormData}
                          value={vendor.taxId}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={display}
                            onChange={() => {
                              setDisplay(!display);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Show on consumer home page"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          marginTop: "50px",
                        }}
                      >
                        <Button
                          onClick={props.handleClose}
                          type="submit"
                          className={classes.clsBtn}
                          sx={{ py: 2 }}
                          variant="contained"
                        >
                          <p className={classes.prpl}>Close</p>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          marginTop: "50px",
                        }}
                      >
                        {loading ? (
                          <CircularProgress
                            strokeColor="#fff"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible
                          />
                        ) : (
                          <Button
                            onClick={handelSubmit}
                            type="submit"
                            className={classes.sbtBtn}
                            sx={{ py: 2 }}
                            variant="contained"
                          >
                            {props.isVendor ? "Update Vendor" : "Create Vendor"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </>
        </Modal>
      </Box>
    </>
  );
}
