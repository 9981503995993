import * as constant from '../../utils/constant';

const initialState = {
  fetching: false,
  dashboardVendor: {},
  error: {},
};

export const GetDashboardVendor = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_DASHBOARD_VENDOR_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_DASHBOARD_VENDOR_SUCCESS:
      return {
        ...state,
        dashboardVendor: action.payload,
        fetching: false,
      };
    case constant.GET_DASHBOARD_VENDOR_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
