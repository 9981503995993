import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAdmin, setAdmin] = useState(
    localStorage.getItem("isAdmin") === "true"
  );
  const [isVendor, setVendor] = useState(
    localStorage.getItem("isVendor") === "true"
  );

  useEffect(() => {
    const storedAdmin = JSON.parse(localStorage.getItem("isAdmin"));
    const storedVendor = JSON.parse(localStorage.getItem("isVendor"));

    if (storedAdmin !== null) {
      setAdmin(storedAdmin);
    }

    if (storedVendor !== null) {
      setVendor(storedVendor);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAdmin, isVendor, setAdmin, setVendor }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
