import * as React from "react";
import {
  Typography,
  Box,
  TextField,
  Grid,
  Stack,
  IconButton,
  Button,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  overflowY: "scroll",
};

export default function OrderModal(props) {
  return (
    <Box>
      <Modal open={props.isOpen} onClose={props.handleClose}>
        <Box sx={style}>
          <Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <Typography variant="h5">
                  {props.isUser ? "Edit Order" : "Add Order"}
                </Typography>
                <IconButton
                  onClick={props.handleClose}
                  aria-label="delete"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Grid>
            <Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    maxWidth: "100",
                    my: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    name="Order"
                    id="Order"
                    label="Order"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    maxWidth: "100",
                    my: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    name="price"
                    id="price"
                    label="Price"
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ my: 3 }}>
                <Button
                  type="submit"
                  style={{ width: "100%" }}
                  sx={{ py: 2 }}
                  variant="contained"
                >
                  {props.isUser ? "Update" : "Add Message"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
