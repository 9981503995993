import React from "react";
import { Grid, Rating, Typography, Avatar } from "@mui/material";
import { useStyles } from "./reviewStyle.js";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";

const Progressbar = ({ bgcolor, label, rating, filterReviewList }) => {
  const ratingCount = filterReviewList?.filter(
    (review) => review.rating === rating
  ).length;
  const totalStarCount = filterReviewList?.reduce(
    (total, review) => total + review.rating,
    0
  );
  const progress = (ratingCount / totalStarCount) * 100;

  const Childdiv = {
    height: 10,
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    position: "relative",
    margin: 10,
  };

  const progresstext = {
    position: "absolute",
    top: -4,
    right: -30,
    paddingLeft: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "black",
    fontWeight: 900,
    fontSize: "12px",
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.progress}>
        <StarIcon
          className={classes.starIcon}
        />
        <span style={{ fontSize: "12px" }}>{label}</span>
        <div style={Childdiv}>
          <span style={progresstext}>{`${ratingCount}`}</span>
        </div>
      </div>
    </>
  );
};

const TotalAverageReview = ({
  totalReview,
  averageReview,
  filterReviewList,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      className={classes.mainGrid}
    >
      <Grid
        item
        xs={4}
        style={{ padding: "15px", borderRight: "1px solid grey" }}
      >
        <Typography className={classes.review}>Total Review</Typography>
        <Typography className={classes.totalReview}>
          {totalReview}
        </Typography>
        <Typography className={classes.grwoth}>
          Growth in review on this year
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{ padding: "15px", borderRight: "1px solid grey" }}
      >
        <Typography className={classes.review}>Average Review</Typography>
        <Rating
          name="product-rating"
          value={averageReview}
          precision={1}
          readOnly
        />
        <Typography className={classes.grwoth}>
          Average rating on this year
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ padding: "15px" }}>
        <Progressbar
          label="5"
          bgcolor="#99ccff"
          rating={5}
          filterReviewList={filterReviewList}
        />
        <Progressbar
          label="4"
          bgcolor="red"
          rating={4}
          filterReviewList={filterReviewList}
        />
        <Progressbar
          label="3"
          bgcolor="#99ff66"
          rating={3}
          filterReviewList={filterReviewList}
        />
        <Progressbar
          label="2"
          bgcolor="#ff00ff"
          rating={2}
          filterReviewList={filterReviewList}
        />
        <Progressbar
          label="1"
          bgcolor="orange"
          rating={1}
          filterReviewList={filterReviewList}
        />
      </Grid>
    </Grid>
  );
};

const ReviewItem = ({ review, handleOpenModal, openReviewModal }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      className={classes.itemContainer}
    >
      <Grid item xs={5} className={classes.borderTop}>
        <Grid container spacing={3} style={{ marginLeft: "15px" }}>
          <Grid
            item
            md={2}
            className={classes.iconView}
            style={{ paddingLeft: "0px" }}
          >
            <Avatar className={classes.avatar} style={{ backgroundColor: "#a98f57 " }}>
              <PersonIcon fontSize="large" />
            </Avatar>
          </Grid>
          <Grid item md={4} style={{ paddingLeft: "10px" }}>
            <Typography variant="h6">{review.user.first_name}</Typography>
            <Typography style={{ fontSize: "15px" }}>
              {review.product.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} className={classes.borderTop}>
        <div className={classes.flexRow}>
          <Rating
            name="product-rating"
            value={review.rating}
            precision={1}
            readOnly
            style={{ fontSize: "20px" }}
          />
          <h6 className={classes.h6}>
            {moment(review.date).format("DD-MM-YYYY")}
          </h6>
        </div>
        <p className={classes.p}>
          {review.comment}
        </p>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.btnView}
      >
        <div className={classes.dltView}>
          <button
            onClick={() => handleOpenModal(review)}
            style={{ marginRight: "10px" }}
          >
            Delete
          </button>
          <button onClick={() => openReviewModal(review)}>Update</button>
        </div>
      </Grid>
    </Grid>
  );
};

export { TotalAverageReview, ReviewItem };
