import { makeStyles } from "@mui/styles";
import bgImg from "../../assets/image/bg-pattern-light.76f84bb3.svg";

export const useStyles = makeStyles({
  loginView: {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  cardContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "10px",
  },
  bgView: {
    backgroundImage: `url("https://hyper-react.coderthemes.com/static/media/bg-pattern-light.76f84bb3.svg"})`,
  },
  mL: {
    marginLeft: "22px"
  },
});
