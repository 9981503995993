import * as actions from "./index";
import { getAdminToken } from "../../utils/Storage";
import { apiInstance } from "../../httpClient";

export const GetDashboardAdminAction = (dispatch) => {
  const AdminToken = getAdminToken();
  dispatch(actions.getDashboardAdminRequest());

  return apiInstance
    .get("counts/all", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    })
    .then((result) => {
      dispatch(actions.getDashboardAdminSuccess(result.data.data));
      return result.data;
    })
    .catch((error) => {
      dispatch(actions.getDashboardAdminError(error?.response?.message));
      throw error;
    });
};
