import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCancelOrders } from "../../redux/action/GetAllCancelOrders";
import moment from "moment";
import { CircularProgress } from "../../components/common_Component/CommonLoader";
import momenttz from 'moment-timezone';

const CancelOrder = ({ search, page, onPageChange, vendor }) => {
  const dispatch = useDispatch();

  const getCancelOrders = useSelector(
    (state) => state?.getCancelOrders?.getAllCancelOrders
  );

  const fetching = useSelector((state) => state?.getCancelOrders?.fetching);

  const handleGetAllCancelOrders = (pageNumber, search, vendor) => {
    dispatch(GetAllCancelOrders(pageNumber + 1, search ?? "", vendor));
  };

  useEffect(() => {
    if (vendor === "All Vendor") {
      handleGetAllCancelOrders(page, search);
    } else {
      handleGetAllCancelOrders(page, search || vendor);
    }
  }, [page, search || vendor]);


  const formatDateInCentralTime = (date) => {
    const timezone = momenttz.tz.guess(); 
    const centralDate = moment(date).tz(timezone);
    return centralDate.format("MM/DD/YYYY, h:mm A");
  };

  const columns = [
    { id: "id", label: "Id", minWidth: 30 },
    { id: "userName", label: "User Name", minWidth: 80 },
    { id: "Product Name", label: "Product Name", minWidth: 100 },
    {
      id: "productCount",
      label: "No. Of Products",
      minWidth: 100,
    },
    {
      id: "date",
      label: "Date",
      minWidth: 100,
    },
    {
      id: "Amount",
      label: "Amount",
      minWidth: 50,
    },
  ];

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column?.id}
                  align={column?.align}
                  style={{
                    minWidth: column?.minWidth,
                    backgroundColor: "#a98f57 ",
                    color: "#fff",
                  }}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {getCancelOrders?.result?.length ? (
                  getCancelOrders?.result?.map((e, index) => {
                    return (
                      <TableRow hover tabIndex={-1}>
                        <TableCell>{e?.orderNumber}</TableCell>
                        <TableCell>
                          {(e?.userDetails?.first_name
                            ? e?.userDetails?.first_name
                            : "-") +
                            " " +
                            (e?.userDetails?.last_name
                              ? e?.userDetails?.last_name
                              : "")}
                        </TableCell>
                        <TableCell>
                          <p
                            className="text-overflow"
                            title={e?.accounting?.cartAccountingList?.map(
                              (product) => product?.productName
                            )}
                          >
                            {e?.accounting?.cartAccountingList?.map(
                              (product) => product?.productName
                            ) + ""}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {e?.accounting?.cartAccountingList?.length}
                        </TableCell>
                        <TableCell>
                          {formatDateInCentralTime(e?.accounting?.createdAt)}
                        </TableCell>
                        <TableCell>
                          ${Number(e?.accounting?.finalTotal).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      No orders available.
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={getCancelOrders?.meta?.totalCount || 0}
        rowsPerPage={10}
        page={page}
        onPageChange={onPageChange}
        labelDisplayedRows={({ from, to, count }) =>
          !count ? "0-0 of 0" : `${from}-${to} of ${count}`
        }
      />
    </>
  );
};

export default CancelOrder;
