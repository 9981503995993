import { toast } from "react-toastify";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const DeleteUserAction = async (id) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post("user/delete", id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    toast.success("User deleted successfully.");
    return res.data;
  } catch (err) {
    toast.error(err.message);
    return null;
  }
};
