import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    padding: "0",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "70px",
    height: "90px",
    border: "1px solid #dee2e6",
    padding: "5px",
    marginTop: "10px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  imgLg: {
    width: "100%",
    height: "250px",
    padding: "5px",
    marginTop: "10px",
    borderRadius: "8px",
    objectFit: "contain",
  },
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
  },
  mainDiv: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
  },
  displaySpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  description: {
    fontSize: "14px",
    color: "#8391a2",
    fontFamily: "Nunito,sans-serif",
    marginTop: "0px",
    paddingRight: "30px",
    lineHeight: "22px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
});
