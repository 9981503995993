import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const UpdateUserAction = async (data) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post("user/update", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    return err.response;
  }
};
