import { Typography } from "@mui/material";

export default function ComponentTitle(props) {
  return (
    <Typography
      variant="h6"
      paddingBottom="15px"
      fontWeight="600"
      color="#343a40"
      fontSize="18"
      paddingLeft="10px"
      gutterBottom
    >
      {props.pageTitle}
    </Typography>
  );
}
