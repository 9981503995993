import * as actions from "./index";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetReviewDetailAction = (id) => async (dispatch) => {
  const AdminToken = getAdminToken();
  try {
    dispatch(actions.getReviewDetailRequest());
    const res = await apiInstance.get(`review/all?productId=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      dispatch(actions.getReviewDetailSuccess(res.data.data, id));
      return res.data;
    }
  } catch (err) {
    dispatch(actions.getReviewDetailError(err.response.message));
    return null;
  }
};
