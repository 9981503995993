import * as actions from "./index";

import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetAllVendorAction = (search, pageNumber) => (dispatch) => {
  const AdminToken = getAdminToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getVendorRequest());
    apiInstance
      .get(`vendor/all?search=${search}&pageNumber=${pageNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.getVendorSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getVendorError(error.response.message));
        return null;
        // reject(error);
      });
  });
};
