import cookie from "react-cookies";

export const getAdminToken = () => {
  // cookie.load("admin-token")
  // localStorage.getItem("admin-token");
  return localStorage.getItem("admin-token");
};

// remove admin
export const removeAdminSession = () => {
  // cookie.remove("admin-token", { path: "/" });
  localStorage.removeItem("admin-token", { path: "/" });
  // localStorage.clear();
};

// set admin
export const setAdminSessionToken = (token) => {
  // cookie.save("admin-token", token, { path: "/" });
  localStorage.setItem("admin-token", token);
};
