import * as actions from "./index";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const UserDetailsAction = (id) => (dispatch) => {
  const AdminToken = getAdminToken();
  new Promise((resolve, reject) => {
    dispatch(actions.setUserDetailRequest());
    apiInstance
      .get(`user?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.setUserDetailSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.setUserDetailError(error.response.message));
        reject(error);
      });
  });
};
