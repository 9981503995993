import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const AddVendor = async (data, setLoading) => {
  const AdminToken = getAdminToken();
  setLoading(true);
  try {
    const res = await apiInstance.post("vendor/add", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    setLoading(false);
    // if (res.status === 200) {
    //   return res.data;
    // }
    return res;
  } catch (err) {
    setLoading(false);
    return err.response;
  }
};
