import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
 container: {
    padding: "0",
  },
    paper: {
    padding: '20px',
  },
  avatar: {
    width: "60px",
    height: "60px",
    backgroundColor: "#a98f57",
  },
  mainView: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
  }
})
)
