import * as actions from "./index";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetExportOrderAction = (startDate, endDate, search, status) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(actions.getExportOrderRequest());
    const AdminToken = getAdminToken();
    apiInstance
      .get(
        `order/getByDate?search=${search}&startDate=${startDate}&endDate=${endDate}&status=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AdminToken}`,
          },
        }
      )
      .then((result) => {
        dispatch(actions.getExportOrderSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getExportOrderError(error.response.message));
        reject(error);
      });
  });
};
