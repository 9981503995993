import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    padding: "0",
  },
  review: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 2,
  },
  grwoth: {
    color: "grey",
    fontSize: "12px",
    fontWeight: "500",
  },
  addButton: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: " #a98f57 ",
    color: "#fff",
    border: "none",
    height: "45px",
    width: "200px",
    borderRadius: "5px",
  },
  progress: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
  },
  starIcon: {
    fontSize: "12px",
    color: "#ffd700",
    marginRight: "5px",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  body1: {
    marginTop: 20,
    padding: "10px",
  },
  displayRigth: {
    display: "flex",
    justifyContent: "right",
  },
  mainGrid: { 
    backgroundColor: "#fff", padding: "15px", marginTop: "15px" 
  },
  totalReview: {
    fontSize: "18px", fontWeight: "900"
  },
  itemContainer: {
  backgroundColor: "#fff", padding: "15px"
  },
  borderTop: {
    borderTop: "1px solid #adb5bd" 
  },
  iconView: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  flexRow: {
    flexDirection: "row", display: "flex"
  },
  h6: {
    margin: "2px", paddingLeft: "6px"
  },
  p: {
    paddingRight: "100px", fontSize: "14px" 
  },
  btnView: {
    borderTop: "1px solid #adb5bd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dltView: {
    display: "flex", justifyContent: "center" 
  },
});
