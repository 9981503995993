import * as actions from "./index";

import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetVendorProfile = (id) => (dispatch) => {
  new Promise((resolve, reject) => {
    dispatch(actions.getVendorProfileRequest());
    const AdminToken = getAdminToken();
    apiInstance
      .get(`vendor?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.getVendorProfileSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getVendorProfileError(error.response.message));
        reject(error);
      });
  });
};
