import React, { useEffect } from "react";
import { ProductApproval } from "../../redux/action/ProductApproval";
import {
  Modal,
  Typography,
  Box,
  TextField,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { RotatingLines } from "../../components/common_Component/CommonLoader";

const ApproveProduct = ({
  productData,
  GetAllProduct,
  search,
  page,
  style,
  setApproveProductModal,
  selectedProduct,
  approveProductModal,
  handleApproveProductModal,
  setProductData,
}) => {
  const [markupPrice, setMarkupPrice] = React.useState(0);
  const [priceError, setPriceError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [priceType, setPriceType] = React.useState("Flat");

  useEffect(() => {
    setMarkupPrice(productData?.markup_price ?? 0);
    setPriceType(productData?.markup_type ?? "Flat");
  }, [productData]);

  const handleChange = (event) => {
    const selectedPriceType = event.target.value ?? "Flat";
    setPriceType(selectedPriceType);
    if (selectedPriceType === "Flat") {
      setMarkupPrice(0);
    }
  };

  const handleOnSite = async () => {
    setLoading(true);
    const data = {
      product_id: productData?._id,
      markup_price: markupPrice,
      markup_type: priceType,
    };
    try {
      const res = await ProductApproval(data);
      if (res?.isSuccess) {
        setLoading(false);
        setApproveProductModal(false);
        setProductData({});
        GetAllProduct(search, selectedProduct, page);
      }
    } catch (error) {
      setApproveProductModal(false);
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <>
      <Modal
        open={approveProductModal}
        onClose={handleApproveProductModal}
        aria-labelledby="approve-product-title"
        aria-describedby="approve-product-description"
      >
        <Box sx={style}>
          <Typography
            id="approve-product-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "20px" }}
          >
            Pending Approve Product
          </Typography>
          <FormControl
            className="markup_price_button"
            sx={{
              marginBottom: "10px",
            }}
          >
            <FormLabel id="markup-radio-buttons-group-label">
              Markup Price
            </FormLabel>
            <RadioGroup
              value={priceType}
              onChange={handleChange}
              aria-labelledby="markup-radio-buttons-group-label"
              defaultValue="Flat"
              name="radio-buttons-group"
              fullWidth
            >
              <FormControlLabel value="Flat" control={<Radio />} label="Flat" />
              <FormControlLabel
                value="Percentage"
                control={<Radio />}
                label="Percentage"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              maxWidth: "380px",
              my: 0,
            }}
          >
            <TextField
              fullWidth
              name="markupPrice"
              id="markupPrice"
              variant="outlined"
              type="number"
              onChange={(e) => {
                setMarkupPrice(e.target.value);
              }}
              value={markupPrice}
              sx={{
                marginBottom: "10px",
              }}
            />

            <Button
              variant="contained"
              sx={{
                mt: "20px",
              }}
              onClick={handleOnSite}
            >
              {loading ? (
                <RotatingLines />
              ) : (
                "On Site"
              )}
            </Button>
          </Box>
          {priceError && <div className="invalid-msg mt-2">{priceError}</div>}
        </Box>
      </Modal>
    </>
  );
};

export default ApproveProduct;
