import * as constant from "../../utils/constant";

const initialState = {
  fetching: false,
  categoriesGetAllData: {},
  error: {},
};

export const getAllCategories = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesGetAllData: action.payload,
        fetching: false,
      };
    case constant.GET_CATEGORIES_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
