// export const baseURL = "http://192.168.0.172:8000/api/v1/app/";
// export const baseURL = "http://localhost:8000/api/v1/app/";
// export const baseURL = "https://api.drjacks.coffee/api/v1/app/";
export const imageURL = "https://api.drjacks.coffee/uploads/";

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const GET_VENDOR_PROFILE_REQUEST = "GET_VENDOR_PROFILE_REQUEST";
export const GET_VENDOR_PROFILE_SUCCESS = "GET_VENDOR_PROFILE_SUCCESS";
export const GET_VENDOR_PROFILE_ERROR = "GET_VENDOR_PROFILE_ERROR";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_ERROR = "GET_REVIEW_ERROR";

export const GET_CART_PRODUCT_REQUEST = "GET_CART_PRODUCT_REQUEST";
export const GET_CART_PRODUCT_SUCCESS = "GET_CART_PRODUCT_SUCCESS";
export const GET_CART_PRODUCT_ERROR = "GET_CART_PRODUCT_ERROR";

export const GET_PAID_ORDERS_REQUEST = "GET_PAID_ORDERS_REQUEST";
export const GET_PAID_ORDERS_SUCCESS = "GET_PAID_ORDERS_SUCCESS";
export const GET_PAID_ORDERS_ERROR = "GET_PAID_ORDERS_ERROR";

export const GET_SHIPPED_ORDERS_REQUEST = "GET_SHIPPED_ORDERS_REQUEST";
export const GET_SHIPPED_ORDERS_SUCCESS = "GET_SHIPPED_ORDERS_SUCCESS";
export const GET_SHIPPED_ORDERS_ERROR = "GET_SHIPPED_ORDERS_ERROR";

export const GET_DECLINED_ORDERS_REQUEST = "GET_DECLINED_ORDERS_REQUEST";
export const GET_DECLINED_ORDERS_SUCCESS = "GET_DECLINED_ORDERS_SUCCESS";
export const GET_DECLINED_ORDERS_ERROR = "GET_DECLINED_ORDERS_ERROR";

export const GET_CANCEL_ORDERS_REQUEST = "GET_CANCEL_ORDERS_REQUEST";
export const GET_CANCEL_ORDERS_SUCCESS = "GET_CANCEL_ORDERS_SUCCESS";
export const GET_CANCEL_ORDERS_ERROR = "GET_CANCEL_ORDERS_ERROR";

export const GET_REVIEW_DETAIL_REQUEST = "GET_REVIEW_DETAIL_REQUEST";
export const GET_REVIEW_DETAIL_SUCCESS = "GET_REVIEW_DETAIL_SUCCESS";
export const GET_REVIEW_DETAIL_ERROR = "GET_REVIEW_DETAIL_ERROR";

export const SET_USER_DETAIL_REQUEST = "SET_USER_DETAIL_REQUEST";
export const SET_USER_DETAIL_SUCCESS = "SET_USER_DETAIL_SUCCESS";
export const SET_USER_DETAIL_ERROR = "SET_USER_DETAIL_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_EXPORT_ORDER_REQUEST = "GET_EXPORT_ORDER_REQUEST";
export const GET_EXPORT_ORDER_SUCCESS = "GET_EXPORT_ORDER_SUCCESS";
export const GET_EXPORT_ORDER_ERROR = "GET_EXPORT_ORDER_ERROR";

export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_ERROR = "GET_ORDER_DETAIL_ERROR";

export const GET_PRODUCT_DETAIL_REQUEST = "GET_PRODUCT_DETAIL_REQUEST";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_ERROR = "GET_PRODUCT_DETAIL_ERROR";

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_ERROR = "GET_VENDOR_ERROR";

export const SET_VENDOR_DETAIL_REQUEST = "SET_VENDOR_DETAIL_REQUEST";
export const SET_VENDOR_DETAIL_SUCCESS = "SET_VENDOR_DETAIL_SUCCESS";
export const SET_VENDOR_DETAIL_ERROR = "SET_VENDOR_DETAIL_ERROR";

export const GET_PRODUCT_VENDOR_REQUEST = "GET_PRODUCT_VENDOR_REQUEST";
export const GET_PRODUCT_VENDOR_SUCCESS = "GET_PRODUCT_VENDOR_SUCCESS";
export const GET_PRODUCT_VENDOR_ERROR = "GET_PRODUCT_VENDOR_ERROR";

export const GET_DASHBOARD_ADMIN_REQUEST = "GET_DASHBOARD_ADMIN_REQUEST";
export const GET_DASHBOARD_ADMIN_SUCCESS = "GET_DASHBOARD_ADMIN_SUCCESS";
export const GET_DASHBOARD_ADMIN_ERROR = "GET_DASHBOARD_ADMIN_ERROR";

export const GET_DASHBOARD_VENDOR_REQUEST = "GET_DASHBOARD_VENDOR_REQUEST";
export const GET_DASHBOARD_VENDOR_SUCCESS = "GET_DASHBOARD_VENDOR_SUCCESS";
export const GET_DASHBOARD_VENDOR_ERROR = "GET_DASHBOARD_VENDOR_ERROR";

export const GET_TRANSACTION_PAYMENT_FAILED_REQUEST = "GET_TRANSACTION_PAYMENT_FAILED_REQUEST";
export const GET_TRANSACTION_PAYMENT_FAILED_SUCCESS = "GET_TRANSACTION_PAYMENT_FAILED_SUCCESS";
export const GET_TRANSACTION_PAYMENT_FAILED_ERROR = "GET_TRANSACTION_PAYMENT_FAILED_ERROR";

export const GET_TRANSACTION_PAYMENT_CANCEL_REQUEST = "GET_TRANSACTION_PAYMENT_CANCEL_REQUEST";
export const GET_TRANSACTION_PAYMENT_CANCEL_SUCCESS = "GET_TRANSACTION_PAYMENT_CANCEL_SUCCESS";
export const GET_TRANSACTION_PAYMENT_CANCEL_ERROR = "GET_TRANSACTION_PAYMENT_CANCEL_ERROR";