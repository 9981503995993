import * as actions from "./index";

import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetAllUserAction = (search, pageNumber, sort) => (dispatch) => {
  const AdminToken = getAdminToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getUserRequest());
    apiInstance
      .get(`user/all?sort=${sort}&search=${search}&pageNumber=${pageNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.getUserSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getUserError(error.response.message));
        reject(error);
      });
  });
};
