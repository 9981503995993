import * as React from "react";
import { useState, useEffect } from "react";
import {
  Container,
  Snackbar,
  Alert,
  Card,
  Avatar,
  Button,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import Iconify from "../../components/iconify";
import UserModal from "../../components/common_Component/UserModal";
import UserListToolbar from "../../sections/@dashboard/user/UserListToolbar";
import styled from "@emotion/styled";
import { useStyles } from "./userStyle";
import { GetAllUserAction } from "../../redux/action/GetUserAction";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUserAction } from "../../redux/action/DeleteUserAction";
import RemoveModal from "../../components/common_Component/RemoveModal";
import { useNavigate } from "react-router-dom";
import { UserDetailsAction } from "../../redux/action/UserDetailsAction";
import { CircularProgress } from "../../components/common_Component/CommonLoader";

const columns = [
  { id: "Name", label: "Name", minWidth: 50 },
  {
    id: "Email",
    label: "Email",
    minWidth: 80,
  },
  {
    id: "Address",
    label: "Address",
    minWidth: 100,
  },
  {
    id: "City",
    label: "City",
    minWidth: 40,
  },
  {
    id: "PhoneNumber",
    label: "Phone Number",
    minWidth: 50,
  },
  // {
  //   id: "Status",
  //   label: "Status",
  //   minWidth: 50,
  // },
  {
    id: "Action",
    label: "Action",
    minWidth: 140,
  },
];

const AddButton = styled(Button)({
  background: "#a98f57 ",
  color: "#f1f3fa",
  width: "150px",
  height: "45px",
  marginTop: "10px",
});

const ActiveButton = styled(Button)({
  background: "#0acf97",
  color: "#fff",
  width: "20px",
  height: "20px",
});

export default function UserPage() {
  const [removeModal, setRemoveModal] = useState(false);
  const handleOpenModal = () => setRemoveModal(true);
  const handleCloseModal = () => setRemoveModal(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState({});
  const [editUserModal, setEditUserModal] = useState(false);
  const [sort, setSort] = useState("atoz");

  const handleOpenEditModal = () => setEditUserModal(true);

  const handleCloseEditModal = () => {
    setEditUserModal(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserGetAll = (search, pageNumber, sort) => {
    dispatch(GetAllUserAction(search, pageNumber + 1, sort));
  };

  const handleUserGetAllState = useSelector(
    (state) => state.GetAllUser.userGetAllData
  );

  const fetching = useSelector((state) => state.GetAllUser.fetching);

  const handleChangePage = (event, page) => {
    setPage(page);
    handleUserGetAll(search, page);
  };

  useEffect(() => {
    handleUserGetAll(search, page); 
  }, [search, page]);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    handleUserGetAll(e.target.value, 0);
  };

  useEffect(() => {
    handleUserGetAll("", page, sort);
  }, [sort]);

  const handelDeleteItem = async (id) => {
    const data = {
      id: id,
    };
    try {
      await DeleteUserAction(data);
      handleCloseModal();
      handleUserGetAll("", page);
    } catch (error) {
      console.log(error);
    }
  };

  const classes = useStyles();

  const handleNavigate = (data) => {
    navigate("/userDetails", { state: data });
  };

  return (
    <>
      <Container className={classes.container}>
        <ComponentTitle pageTitle="Users" />
        <div className={classes.mainView}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "0px",
            }}
          >
            <UserListToolbar
              search={search}
              handleSearch={handleSearchInput}
              name="Name"
            />
            <div className="sort-header">
              <FormControl
                sx={{
                  width: 380,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Select
                  value={sort}
                  onChange={(event) => {
                    setSort(event.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"atoz"}>A to Z</MenuItem>
                  <MenuItem value={"ztoa"}>Z to A</MenuItem>
                </Select>
              </FormControl>
              <AddButton variant="text" className="mt-0" onClick={handleOpen}>
                Create User
              </AddButton>
            </div>
          </div>
          <Card
            sx={{
              marginBottom: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              {fetching ? (
                <CircularProgress />
              ) : (
                <>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#a98f57 ",
                                color: "#fff",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {handleUserGetAllState &&
                        handleUserGetAllState?.result?.length > 0 ? (
                          handleUserGetAllState?.result?.map((row) => {
                            return (
                              <TableRow hover tabIndex={-1} key={row._id}>
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell
                                  style={
                                    {
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }
                                  }
                                >
                                  {row.address}
                                </TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.phone_number}</TableCell>
                                {/* 
                                {row.status === "A" ? (
                                  <TableCell>
                                    <ActiveButton>Active</ActiveButton>
                                  </TableCell>
                                ) : (
                                  <TableCell>
                                    <ActiveButton
                                      style={{ backgroundColor: "red" }}
                                    >
                                      InActive
                                    </ActiveButton>
                                  </TableCell>
                                )} */}
                                <TableCell>
                                  <div style={{display: "flex"}}>
                                    <>
                                      <Tooltip title="View" placement="bottom">
                                        <Iconify
                                          icon={"eva:eye-fill"}
                                          sx={{ mr: 2 }}
                                          onClick={() => {
                                            handleNavigate(row);
                                          }}
                                        />
                                      </Tooltip>
                                      <Tooltip title="Edit" placement="bottom">
                                        <Iconify
                                          icon={"eva:edit-fill"}
                                          sx={{ mr: 2 }}
                                          onClick={() => {
                                            dispatch(
                                              UserDetailsAction(row._id)
                                            );
                                            setUserData(row);
                                            handleOpenEditModal();
                                          }}
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title="Delete"
                                        placement="bottom"
                                      >
                                        <Iconify
                                          icon={"eva:trash-2-outline"}
                                          sx={{ mr: 2 }}
                                          onClick={() => {
                                            setUserData(row);
                                            handleOpenModal();
                                          }}
                                        />
                                      </Tooltip>
                                    </>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} align="center">
                              No Users available.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <UserModal
                    handleClose={handleClose}
                    isOpen={isOpen}
                    page={page}
                    search={search}
                  />
                  <UserModal
                    handleClose={handleCloseEditModal}
                    isUser
                    data={userData}
                    page={page}
                    isOpen={editUserModal}
                    search={search}
                  />
                  <RemoveModal
                    removeModal={removeModal}
                    handleCloseModal={handleCloseModal}
                    handelDeleteItem={() => handelDeleteItem(userData._id)}
                  />
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={handleUserGetAllState?.meta?.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </>
              )}
            </Paper>
          </Card>
        </div>
      </Container>
    </>
  );
}
