import * as React from "react";
import { useState } from "react";
import { Box, TextField, Grid, Button, Modal, Alert } from "@mui/material";
import { UpdateReviewAction } from "../../redux/action/UpdateReviewAction";
import { GetAllReviewAction } from "../../redux/action/GetReviewAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AddReview } from "../../redux/action/AddReviewAction";
import Snackbar from "@mui/material/Snackbar";
import { GetReviewDetailAction } from "../../redux/action/GetReviewDetailAction";
import { GetAllProductAction } from "../../redux/action/GetProductAction";
import { useStyles } from "./commonCss.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "30px",
  borderRadius: 2,
};

export default function ReviewModal(props) {
  const dispatch = useDispatch();
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [isErrorOpen, setErrorOpen] = useState(false);
  const [productName, setProductName] = useState("");

  const [review, setReview] = useState({
    rating: 0,
    comment: "",
  });

  const [error, setError] = useState({
    rating: "",
    comment: "",
  });

  const GetReviewDetail = (id) => dispatch(GetReviewDetailAction(id));

  const GetAllProduct = (search, selectedProduct, pageNumber) =>
    dispatch(GetAllProductAction(search, selectedProduct, pageNumber + 1));

  const getAllProductState = useSelector(
    (state) => state.getAllProduct.productGetAllData
  );
  useEffect(() => {
    if (props.initialData) {
      setReview({
        rating: props.initialData.rating,
        comment: props.initialData.comment,
      });
    }
    setError({
      rating: "",
      comment: "",
    });
  }, [props.initialData]);

  useEffect(() => {
    if (props.data) {
      setProductName(props?.data?.product?.title);
    } else {
      const selectedProductData = getAllProductState?.result?.filter(
        (value) => value._id === props?.selectedProduct
      );
      if (selectedProductData?.length > 0) {
        setProductName(selectedProductData[0]?.title);
      }
      resetReviewFields();
    }
    GetAllProduct("", "", 1);
  }, [props?.data, props?.selectedProduct]);

  const GetAllReview = () => dispatch(GetAllReviewAction());

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    props.handleClose();
    GetAllReview();
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleClear = () => {
    props.handleCloseModal();
    setReview({
      rating: 0,
      comment: "",
    });
  };

  const handleUpdateReview = async () => {
    try {
      if (!review.rating) {
        setError((prevError) => ({
          ...prevError,
          rating: "Please provide a rating.",
        }));
        return;
      }

      if (!review.comment.trim()) {
        setError((prevError) => ({
          ...prevError,
          comment: "Please provide a comment.",
        }));
        return;
      }
      setError({
        rating: "",
        comment: "",
      });

      const { rating, comment } = review;
      const response = await UpdateReviewAction(
        props?.data?._id,
        rating,
        comment
      );

      if (response) {
        setSuccessOpen(true);
        props.handleClose();
        if (props?.selectedProduct?.length > 0) {
          GetReviewDetail(props?.selectedProduct);
        } else {
          GetAllReview();
        }
        resetReviewFields();
      } else {
        setErrorOpen(true);
      }
    } catch (error) {
      alert("Error in review update:", error);
    }
  };

  const resetReviewFields = () => {
    setReview({
      rating: 0,
      comment: "",
    });
    setError({
      rating: "",
      comment: "",
    });
  };

  const handleAddReview = async () => {
    try {
      resetReviewFields();

      if (!review.rating) {
        setError((prevError) => ({
          ...prevError,
          rating: "Please provide a rating.",
        }));
        return;
      }

      if (!review.comment.trim()) {
        setError((prevError) => ({
          ...prevError,
          comment: "Please provide a comment.",
        }));
        return;
      }
      setError({
        rating: "",
        comment: "",
      });

      const { rating, comment } = review;
      const response = await AddReview(props?.selectedProduct, rating, comment);

      if (response && response.isSuccess === true) {
        setSuccessOpen(true);
        setTimeout(() => {
          setSuccessOpen(false);
          props.handleCloseModal();
          GetReviewDetail(props?.selectedProduct);
        }, 2000);
      } else {
        setErrorOpen(true);
        setError(response?.displayMsg || "Something Went Wrong");
      }
    } catch (error) {
      console.log("Error in adding review:", error);
    }
  };

  const handleRatingChange = (e) => {
    let value = e.target.value;
    value = Math.min(Math.max(value, 1), 5);
    setReview({ ...review, rating: value });
  };

  const handleCloseModal = () => {
    resetReviewFields();
    props.handleCloseModal();
  };

  const classes = useStyles();
  return (
    <>
      <div>
        <Snackbar
          open={isSuccessOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
        >
          <Alert severity="success" onClose={handleSuccessClose}>
            {props.data
              ? "Review updated successfully!"
              : "Review added successfully!"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isErrorOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
        >
          <Alert severity="error" onClose={handleErrorClose}>
            {props.data ? "Failed to update review." : "Failed to add review."}
          </Alert>
        </Snackbar>
      </div>
      <Box>
        <Modal open={props.isOpen} onClose={handleCloseModal}>
          <Box sx={style}>
            <Grid
              container
              spacing={2}
              style={{ flexDirection: "column", display: "flex" }}
            >
              <Box style={{ justifyContent: "center", display: "flex" }}>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    margin: "5px",
                  }}
                >
                  {productName}
                </p>
              </Box>
              <Grid item xs={6} style={{ maxWidth: "435px" }}>
                <Box>
                  <TextField
                    style={{ width: "100%" }}
                    name="rating"
                    id="rating"
                    label="Rating"
                    variant="outlined"
                    type="number"
                    value={review.rating}
                    onChange={handleRatingChange}
                    error={Boolean(error.rating)}
                    helperText={error.rating}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ maxWidth: "435px", padding: "30px 0px 50px 0px" }}
              >
                <Box>
                  <TextField
                    style={{ width: "100%" }}
                    name="comment"
                    id="comment"
                    label="Comment"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={review.comment}
                    onChange={(e) =>
                      setReview({ ...review, comment: e.target.value })
                    }
                    error={Boolean(error.comment)}
                    helperText={error.comment}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  <Button
                    onClick={handleClear}
                    type="submit"
                    style={{
                      width: "200px",
                      height: "55px",
                    }}
                    sx={{ py: 2 }}
                    variant="contained"
                  >
                    <p className={classes.prpl}>Close</p>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Button
                    onClick={props.data ? handleUpdateReview : handleAddReview}
                    type="submit"
                    style={{
                      width: "200px",
                      height: "55px",
                      marginLeft: "10px",
                      backgroundColor: "#a98f57 ",
                    }}
                    sx={{ py: 2 }}
                    variant="contained"
                  >
                    {props.data ? "Update Review" : "Add Review"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
