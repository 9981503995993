// theme
import ThemeProvider from "./theme";

import "./css/style.css";
// components
import ScrollToTop from "./components/scroll-to-top";
import Router from "./routes";
import { apiInstance } from "./httpClient";
import { getAdminToken } from "./utils/Storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminToken = getAdminToken();

export default function App() {
  Object.assign(apiInstance.defaults.headers, {
    Authorization: `Bearer ${AdminToken}`,
  });
  return (
    <ThemeProvider>
      <ToastContainer position="bottom-right" />
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
