import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
// component
import Iconify from "../../../components/iconify";
// ---------------------------------------------------------------------- added better commenting

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  paddingLeft: 0,
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 200,
  height: 45,
  fontSize: 14,

  // "&.Mui-focused": {
  //   width: 320,
  // },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar(props) {
  return (
    <StyledRoot
      sx={{
        ...(props.numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {props.numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {props.numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          sx={{ width: props.width }}
          value={props.search}
          onChange={props.handleSearch}
          placeholder={`Search ${props.name}...`}
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 15, height: 15 }}
              />
            </InputAdornment>
          }
        />
      )}
    </StyledRoot>
  );
}
