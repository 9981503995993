import * as actions from "./index";

import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetOrderDetailAction = (order_id) => (dispatch) => {
  const AdminToken = getAdminToken();
  new Promise((resolve, reject) => {
    dispatch(actions.getOrderDetailRequest());
    apiInstance
      .get(`order?order_id=${order_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.getOrderDetailSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getOrderDetailError(error.response.message));
        reject(error);
      });
  });
};
