import React, { useState } from "react";
import { Container, Grid} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./productStyle.js";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
// import { imageURL } from "../../utils/constant";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { GetProductDetailAction } from "../../redux/action/ProductDetailAction.js";
import { CircularProgress } from "../../components/common_Component/CommonLoader.js";

export default function ProductDetails() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const imageURL = process.env.REACT_APP_IMAGE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const productDetail = useSelector(
    (state) => state.getProductDetail.productDetails
  );

  const handleDetails = async () => {
    const productId = location.state._id;
    dispatch(GetProductDetailAction(productId));
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    handleDetails();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const fetching = useSelector((state) => state.getProductDetail.fetching);

  const classes = useStyles();
  return (
    <>
      {fetching || loading ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ArrowBackIcon style={{ margin: "2px" }} onClick={handleGoBack} />
            <ComponentTitle pageTitle="Product Details" />
          </div>
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: "#fff",
                padding: "15px",
              }}
            >
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                loop={true}
                spaceBetween={10}
                navigation={false}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
                style={{
                  display: "block",
                  objectFit: "contain",
                  border: "1px solid #dee2e6",
                }}
              >
                {productDetail?.image &&
                  productDetail?.image?.length > 0 &&
                  productDetail?.image?.map((image, index) => (
                    <>
                      <SwiperSlide key={index} className={classes.flex}>
                        <img
                          src={`${imageURL + image}`}
                          crossOrigin="anonymous"
                          className={classes.imgLg}
                        />
                      </SwiperSlide>
                    </>
                  ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {productDetail?.image?.map((image, index) => (
                  <SwiperSlide key={index} className={classes.flex}>
                    <img
                      src={`${imageURL + image}`}
                      crossOrigin="anonymous"
                      className={classes.img}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                backgroundColor: "#fff",
                padding: "15px",
                maxWidth: "57%",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "1px",
                  marginTop: "10px",
                }}
              >
                PRICE:
              </p>
              <h5
                style={{
                  fontSize: "24px",
                  color: "#6C757C",
                  fontWeight: "bold",
                  fontFamily: "Nunito,sans-serif",
                  marginTop: "0px",
                  marginBottom: "10px",
                }}
              >
                ${productDetail.price}
              </h5>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "0px",
                }}
              >
                DESCRIPTION:
              </p>
              <p className={classes.description}>{productDetail.description}</p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                MISSIONS:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#8391a2",
                  fontFamily: "Nunito,sans-serif",
                  marginTop: "0px",
                  paddingRight: "30px",
                  lineHeight: "22px",
                }}
              >
                {productDetail?.missions}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                ORIGINS:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#8391a2",
                  fontFamily: "Nunito,sans-serif",
                  marginTop: "0px",
                  paddingRight: "30px",
                  lineHeight: "22px",
                }}
              >
                {productDetail?.origins}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                ROAST:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#8391a2",
                  fontFamily: "Nunito,sans-serif",
                  marginTop: "0px",
                  paddingRight: "30px",
                  lineHeight: "22px",
                }}
              >
                {productDetail?.roast}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                BEAN:
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "#8391a2",
                  fontFamily: "Nunito,sans-serif",
                  marginTop: "0px",
                  paddingRight: "30px",
                  lineHeight: "22px",
                }}
              >
                {Array.isArray(productDetail?.bean) &&
                  productDetail.bean.map((bean, index) => (
                    <p key={index} style={{ margin: 0 }}>
                      {bean}
                    </p>
                  ))}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#6C757C",
                  fontFamily: "Nunito,sans-serif",
                  marginBottom: "0px",
                  marginTop: "10px",
                }}
              >
                VENDOR:
                <p
                  style={{
                    fontSize: "14px",
                    color: "#8391a2",
                    fontFamily: "Nunito,sans-serif",
                    marginTop: "0px",
                    paddingRight: "30px",
                    lineHeight: "22px",
                  }}
                >
                  {productDetail?.vendorDetails?.first_name}{" "}
                  {productDetail?.vendorDetails?.last_name}
                </p>
              </p>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
