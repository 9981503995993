import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const DeleteVendorAction = async (id) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post("vendor/delete", id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    return res.data;
  } catch (err) {
    return null;
  }
};
