import * as constant from '../../utils/constant';

const initialState = {
  fetching: false,
  orderGetDetail: {},
  error: {},
};

export const GetOrderDetail = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderGetDetail: action.payload,
        fetching: false,
      };
    case constant.GET_ORDER_DETAIL_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
