import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const UpdateVendorAction = async (data) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post("vendor/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    return err.response;
  }
};
