// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import { useAuthContext } from "../AuthContext";
import { useLocation } from "react-router-dom";

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Header() {
  const { isAdmin } = useAuthContext();
  const location = useLocation();
  const vendor = location?.state?.vendor;
  const vendorName = localStorage.getItem("vendorName");

  return (
    <StyledRoot>
      <StyledToolbar>
        {!isAdmin && (
          <>
            <Typography variant="body1" color="#000">
              <p style={{ marginRight: "4px" }}>Welcome,</p>
            </Typography>

            <Typography variant="h6" color="#000">
              {" " + (vendor?.first_name ?? vendorName) ?? ""}{" "}
              {vendor?.last_name ?? ""}
            </Typography>
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
