import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    padding: "0",
  },
  table: {
    flexDirection: "row",
    display: "flex",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  card: {
    backgroundColor: "#fff",
    width: "200px",
    height: "200px",
    margin: "10px",
  },
  title: { fontSize: 16, fontWeight: 700, color: "#8391a2" },
  price: { fontSize: 20, fontWeight: 900, marginTop: "20px", color: "#313a46" },
  pr: { fontSize: 14, fontWeight: 500, marginTop: "40px" },
  time: { fontSize: 14, fontWeight: 700, marginTop: 5, color: "#8391a2" },
  week: {
    backgroundColor: "#f6f8fa",
    height: "115px",
    textAlign: "center",
  },
  weekText: { marginTop: "20px", color: "#98a6ad" },
  weekIncome: { marginTop: "-10px", color: "#6c757d" },
  cityTitle: { fontSize: 14, fontWeight: 700, color: "#8391a2" },
  h4: { color: "#6c757d", lineHeight: "10px", paddingLeft: "15px" },
  p: { color: "#98a6ad", lineHeight: "10px", paddingLeft: "15px" },
});
