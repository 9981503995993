export const Colors = {
    WHITE: '#fff',
    JANITOR_BLUE: '#a98f57 ',
    BLUE: '#1877F2',
    JETSKI_BLUE: '#006097',
    LIGHT_BLUE: '#1C9CEA',
    RED_BARON: '#ba000d',
    RED: '#DF3E30',
    CARBON_BLACK: '#333333',
    GERY: '#ebebeb',
}