import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const DeleteReviewAction = async (id) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post(
      "review/delete",
      { id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return null;
  }
};
