import { toast } from "react-toastify";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const ChangePasswordAction = async (id, data, setLoading) => {
  setLoading(true);
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post(`vendor/updatePassword/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AdminToken}`,
      },
    });
    setLoading(false);
    if (res.status === 200) {
      return res.data;
    }
    return null;
  } catch (err) {
    setLoading(false);
    toast.error(
      err?.response?.data?.data?.oldPassword ??
        "" + err?.response?.data?.data?.newPassword ??
        "" + err?.response?.data?.data?.confirmPassword ??
        ""
    );
    return err.response;
  }
};
