import * as actions from "./index";

import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const GetAllOrderAction = (search, pageNumber) => (dispatch) => {
  new Promise((resolve, reject) => {
    dispatch(actions.getOrderRequest());
    const AdminToken = getAdminToken();
    apiInstance
      .get(`order?search=${search}&pageNumber=${pageNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.getOrderSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.getOrderError(error.response.message));
        reject(error);
      });
  });
};
