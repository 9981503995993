import * as actions from "./index";
import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const VendorDetailsAction = (id) => (dispatch) => {
  const AdminToken = getAdminToken();
  new Promise((resolve, reject) => {
    dispatch(actions.setVendorDetailRequest());
    apiInstance
      .get(`vendor?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      })
      .then((result) => {
        dispatch(actions.setVendorDetailSuccess(result.data.data));
        resolve(result.data);
      })
      .catch((error) => {
        dispatch(actions.setVendorDetailError(error.response.message));
        reject(error);
      });
  });
};
