import * as constant from "../../utils/constant";

const initialState = {
  fetching: false,
  getAllCartProduct: {},
  error: {},
};

export const getCartProduct = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_CART_PRODUCT_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case constant.GET_CART_PRODUCT_SUCCESS:
      return {
        ...state,
        getAllCartProduct: action.payload,
        fetching: false,
      };
    case constant.GET_CART_PRODUCT_ERROR:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
