import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./userStyle.js";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { UserDetailsAction } from "../../redux/action/UserDetailsAction.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "../../components/common_Component/CommonLoader.js";

export default function UserDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state.setUserDetail.setUserData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleDetails = async () => {
    dispatch(UserDetailsAction(location.state._id));
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    handleDetails();
  }, []);

  const hanldeGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ArrowBackIcon style={{ margin: "2px" }} onClick={hanldeGoBack} />
            <ComponentTitle pageTitle="User Details" />
          </div>
          <Paper className={classes.paper}>
            <Grid item xs={12} md={4} display={"flex"}>
              <Typography variant="h6" marginRight={"5px"}>
                {userDetails.first_name}
              </Typography>
              <Typography variant="h6">{userDetails.last_name}</Typography>
            </Grid>
            <Divider style={{ margin: "16px 0" }} />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <EmailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Email" secondary={userDetails.email} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <PhoneIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Phone"
                  secondary={userDetails.phone_number}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LocationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Location"
                  secondary={`${userDetails.address}, ${userDetails.address2}, ${userDetails.city}, ${userDetails.country}, ${userDetails.state}, ${userDetails.pincode}`}
                />
              </ListItem>
            </List>
          </Paper>
        </Container>
      )}
    </>
  );
}
