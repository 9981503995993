import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Autocomplete,
  Stack,
  TextField,
} from "@mui/material";
import { useStyles } from "./reviewStyle.js";
import ComponentTitle from "../../components/common_Component/pageTitle/ComponentTitle.js";
import { useDispatch, useSelector } from "react-redux";
import { GetAllReviewAction } from "../../redux/action/GetReviewAction.js";
import { GetAllProductAction } from "../../redux/action/GetProductAction.js";
import { GetReviewDetailAction } from "../../redux/action/GetReviewDetailAction.js";
import ReviewModal from "../../components/common_Component/ReviewModal.js";
import { DeleteReviewAction } from "../../redux/action/DeleteReviewAction.js";
import RemoveModal from "../../components/common_Component/RemoveModal.js";
import { TotalAverageReview, ReviewItem } from "./ReviewItem.js";
import { CircularProgress } from "../../components/common_Component/CommonLoader.js";

const ReviewPage = () => {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [reviewModalData, setReviewModalData] = useState();
  const [isAddReviewModalOpen, setAddReviewModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);

  const GetAllReview = () => dispatch(GetAllReviewAction());
  const GetAllProduct = (search, selectedProduct, pageNumber) =>
    dispatch(GetAllProductAction(search, selectedProduct, pageNumber));

  const GetReviewDetail = (id) => dispatch(GetReviewDetailAction(id));

  const getAllReviewState = useSelector(
    (state) => state.getAllReview.reviewGetAllData
  );

  const fetching = useSelector((state) => state.getAllReview.fetching);

  const getAllProductState = useSelector(
    (state) => state.getAllProduct.productGetAllData
  );

  const selectedProductReviews = useSelector(
    (state) => state.getReviewDetail.reviewDetail
  );

  const filterReviewList = getAllReviewState?.filter(
    (item) => item.status === "A"
  );

  const filteredSelectedProductReviews = selectedProductReviews?.filter(
    (item) => item.status === "A"
  );

  const totalReviews = filterReviewList.reduce(
    (total, review) => total + review.rating,
    0
  );

  const totalReview = filterReviewList?.length;

  const totalRatingSum = filterReviewList?.reduce(
    (sum, review) => sum + review.rating,
    0
  );

  const averageReview = totalRatingSum / totalReview;

  const totalSelectedProductReviews = filteredSelectedProductReviews?.length;

  const totalRatingSumSelectedProduct = filteredSelectedProductReviews?.reduce(
    (sum, review) => sum + review?.rating,
    0
  );
  const averageReviewSelectedProduct =
    totalRatingSumSelectedProduct / totalSelectedProductReviews;

  useEffect(() => {
    GetAllProduct(search || "", "", page);
    GetAllReview();
  }, [page]);

  const handleOpenModal = (review) => {
    setSelectedReview(review);
    setRemoveModal(true);
  };
  const handleCloseModal = () => setRemoveModal(false);

  const ProductDropdown = ({
    products,
    selectedProduct,
    onChange,
    selectedProductName,
  }) => {
    const [inputValue, setInputValue] = useState(selectedProductName || "");

    const handleAutocompleteChange = (_, value) => {
      if (value === "All Products") {
        onChange({ target: { value: 0 } });
        setInputValue("All Products");
      } else if (value) {
        const selectedProduct = products?.result?.find(
          (product) => product.title === value
        );
        if (selectedProduct) {
          onChange({ target: { value: selectedProduct._id } });
          setInputValue(selectedProduct.title);
        }
      }
    };

    const autocompleteOptions = [
      "All Products",
      ...(products?.result?.map((option) => option.title) || []),
    ];

    return (
      <>
        <Stack spacing={2} sx={{ width: 200 }}>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={autocompleteOptions}
            value={inputValue}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Product"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Stack>
      </>
    );
  };

  const handleProductChange = (event) => {
    const selectedProductId = event.target.value;
    GetReviewDetail(selectedProductId);
    setSelectedProduct(selectedProductId);
  };

  const handelDeleteItem = async () => {
    try {
      await DeleteReviewAction(selectedReview._id);
      handleCloseModal();
      if (selectedProduct?.length > 0) {
        GetReviewDetail(selectedProduct);
      } else {
        GetAllReview();
      }
      handleShowAlert();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const openReviewModal = (review) => {
    setSelectedReview(review);
    setReviewModalOpen(true);
    setReviewModalData({
      rating: review.rating,
      comment: review.comment,
    });
  };

  const closeReviewModal = () => {
    setReviewModalOpen(false);
  };

  const filterReviewsByProduct = (productId) => {
    if (productId === "0") {
      return filterReviewList;
    } else {
      return filterReviewList.filter(
        (review) => review.product._id === productId
      );
    }
  };

  const handleReviewAdded = () => {
    setAddReviewModalOpen(false);
    setReviewModalOpen(false);
    setSelectedReview(null);
  };

  const handleClose = () => {
    setAddReviewModalOpen(false);
    setReviewModalOpen(false);
  };

  const handleShowAlert = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  const classes = useStyles();
  return (
    <>
      {fetching ? (
        <CircularProgress />
      ) : (
        <Container className={classes.container}>
          <div className={classes.row}>
            <ComponentTitle pageTitle="Product Review" />
            <Grid container justifyContent="flex-end" style={{ width: "30%" }}>
              <ProductDropdown
                products={getAllProductState}
                selectedProduct={selectedProduct}
                onChange={handleProductChange}
              />
            </Grid>
          </div>

          {selectedProduct?.length > 0 ? (
            <>
              <div>
                {totalSelectedProductReviews > 0 && (
                  <TotalAverageReview
                    totalReviews={totalSelectedProductReviews}
                    averageReview={averageReviewSelectedProduct}
                    filterReviewList={filterReviewsByProduct(selectedProduct)}
                  />
                )}
                {filteredSelectedProductReviews?.length > 0 ? (
                  filteredSelectedProductReviews.map((review, index) => {
                    return (
                      <ReviewItem
                        review={review}
                        handleOpenModal={handleOpenModal}
                        openReviewModal={openReviewModal}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <Typography variant="body1" style={{ marginTop: 20 }}>
                    No reviews available for this product.
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <div>
              {totalReviews > 0 && (
                <TotalAverageReview
                  totalReview={totalReview}
                  averageReview={averageReview}
                  filterReviewList={filterReviewList}
                />
              )}
              {filterReviewList?.length > 0 ? (
                filterReviewList.map((review, index) => (
                  <ReviewItem
                    review={review}
                    handleOpenModal={handleOpenModal}
                    openReviewModal={openReviewModal}
                    key={index}
                  />
                ))
              ) : (
                <Typography variant="body1" className={classes.body1}>
                  No reviews available.
                </Typography>
              )}
            </div>
          )}

          <ReviewModal
            isOpen={isReviewModalOpen || isAddReviewModalOpen}
            inReview
            handleClose={closeReviewModal}
            selectedProduct={selectedProduct}
            data={selectedReview}
            initialData={reviewModalData}
            onReviewAdded={handleReviewAdded}
            handleCloseModal={handleClose}
            isAddReviewModalOpen={isAddReviewModalOpen}
          />
          <RemoveModal
            removeModal={removeModal}
            handleCloseModal={handleCloseModal}
            handelDeleteItem={() => handelDeleteItem()}
            message="Review"
          />
        </Container>
      )}
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          Review deleted successfully.
        </Alert>
      </Snackbar>
    </>
  );
};
export default ReviewPage;
