import { combineReducers } from "redux";
import { GetAllUser } from "./GetUser";
import { getAllProduct } from "./GetProduct";
import { getAllReview } from "./GetReview";
import { getReviewDetail } from "./GetReviewDetail";
import { setUserDetail } from "./SetUserDetail";
import { GetAllOrder } from "./GetOrder";
import { GetOrderDetail } from "./GetOrderDeatil";
import { getProductDetail } from "./GetProductDetail";
import { GetAllVendor } from "./GetVendor";
import { setVendorDetail } from "./SetVendorDetail";
import { getAllProductVendor } from "./GetProductVendor";
import { GetDashboardAdmin } from "./GetDashoboardAdmin";
import { GetDashboardVendor } from "./GetDashoboardVendor";
import { getAllCategories } from "./GetCategories";
import { GetExportOrder } from "./GetExportOrder";
import { getCartProduct } from "./GetCartProduct";
import { GetPaidOrders } from "./GetPaidOrders";
import { getShippedOrders } from "./GetShippedOrders";
import { getCancelOrders } from "./GetCancelOrders";
import { getDeclinedOrders } from "./GetDeclinedOrders";
import { getVendorProfile } from "./GetVendorProfile";
import {getTransactionPaymentCanceled} from "./GetTransactionPaymentCanceled";
import {getTransactionPaymentFailed} from './GetTransactionFailed';

export const rootReducer = combineReducers({
  GetAllUser,
  GetPaidOrders,
  getAllProduct,
  getAllReview,
  getReviewDetail,
  setUserDetail,
  GetAllOrder,
  GetExportOrder,
  GetOrderDetail,
  getProductDetail,
  GetAllVendor,
  setVendorDetail,
  getAllProductVendor,
  GetDashboardAdmin,
  GetDashboardVendor,
  getAllCategories,
  getCartProduct,
  getShippedOrders,
  getCancelOrders,
  getDeclinedOrders,
  getVendorProfile,
  getTransactionPaymentCanceled,
  getTransactionPaymentFailed
});
