import { apiInstance } from "../../httpClient";
import { getAdminToken } from "../../utils/Storage";

export const DeleteProductAction = async (product_id) => {
  const AdminToken = getAdminToken();
  try {
    const res = await apiInstance.post(
      "product/delete",
      {
        product_id: product_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AdminToken}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return null;
  }
};
